import React, { useCallback, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';

import { PageSectionComponent } from '../types';
import { useResolveAlbums } from './utils';

import HorizontalItemsSection, {
  HorizontalItemsSectionProps,
} from '../lib/HorizontalItemsSection';

import { usePageTheme } from '../../ItemPageEdit/addons/theme/PageThemeContext';
import { ClickableOnClick } from '~/app/components/Clickable';
import getPublicEndpoint from '~/app/lib/getPublicEndpoint';
import { useRegisterNavItem } from '../../ItemPageNav';
import useOpenLink from '../lib/useOpenLink';
import Box from '~/app/components/Box';

import { ReleasesSectionProps } from './types';
import { MAX_ITEMS } from './constants';

const PagePeakDynamic = dynamic(() => import('../lib/PagePeek'), {
  ssr: false,
});

const ReleasesSection: PageSectionComponent<ReleasesSectionProps> = ({
  title,
  albums,
  albumIds = albums?.map(({ id }) => id).slice(0, MAX_ITEMS) ?? [],
  sectionId,
  sectionIndex,
  pagePeeking,
  navTitle,
}) => {
  const items = useResolveAlbums({ albumIds, albums });
  const [pagePeakPath, setPagePeakPath] = useState<string>();
  const openLink = useOpenLink();
  const pageTheme = usePageTheme();

  if (!items) {
    return null;
  }

  useRegisterNavItem({
    id: sectionId,
    text: navTitle,
    index: sectionIndex,
  });

  const onItemClick = useCallback<
    ClickableOnClick<{ url: string; name: string }>
  >(({ event, data: { url, name } }) => {
    event.preventDefault();

    openLink({
      url,
      onPagePeek: setPagePeakPath,
      pagePeeking,
      name,
    });
  }, []);

  return (
    <Box testId="releases">
      <HorizontalItemsSection
        title={title}
        backgroundColor={pageTheme.backgroundColor}
        items={useMemo<HorizontalItemsSectionProps['items']>(() => {
          return items.map(({ name, image, id, pagePath }) => {
            const url = new URL(pagePath, getPublicEndpoint()).toString();

            return {
              key: id,
              image,
              // use the full url to ensure links work under CustomDomain
              href: url,
              text: name,
              testId: 'releasesItem',
              textLineClamp: 1,
              imageStyle: {
                background: '#111',
                border: 'solid 1px #333',
              },

              onClick: onItemClick,
              data: {
                url,
                name,
              },
            };
          }, []);
        }, [albums])}
      />
      {pagePeakPath && (
        <PagePeakDynamic
          pagePath={pagePeakPath}
          onClose={() => {
            setPagePeakPath(undefined);
          }}
        />
      )}
    </Box>
  );
};

export default ReleasesSection;
